<template>
  <v-container fluid class="primary">
    <v-row no-gutters style="height: 100vh" justify="center">
      <v-col md="4" align-self="center">
        <v-card class="pa-2 " outlined tile>
          <p class="text-center mt-10">
            <img
              src="@/assets/images/redd-logo.svg"
              alt=""
              style="width: 40%"
              class="mx-auto "
            />
          </p>
          <v-card-title>Password Reset</v-card-title>
          <v-card-subtitle>Please select a new password</v-card-subtitle>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field v-model="username" required readonly>
                <template slot="label">Username</template>
              </v-text-field>

              <v-text-field v-model="password" :type="passwordField" required>
                <template slot="label">Password</template>
              </v-text-field>

              <v-text-field v-model="password2" :type="passwordField" required>
                <template slot="label">Confirm Password</template>
              </v-text-field>
              <!-- <div v-if="showForgotPassword === true"> -->
              <v-btn color="primary" class="px-4" @click="savePassword">
                Update Password
              </v-btn>

              <!-- </div> -->
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const axios = require("axios");
axios.defaults.withCredentials = true;
import helpers from "@/plugins/helpers";
import moment from "moment";
export default {
  data: () => ({
    secret: "",
    username: "",
    password: "",
    passwordField: "password",
    password2: "",
    valid: true,
    showPassword: false,
    loader: null,
    loading: false
  }),
  computed: {
    token() {
      return this.$store.state.user.token;
    }
  },
  methods: {
    login() {
      let vm = this;
      if (this.$refs.form.validate()) {
        axios
          .post(vm.$root.urls.api + "/login/admin", {
            username: vm.username,
            password: vm.password
          })
          .then(function(results) {
            if (results.data.user && results.data.user.role !== "customer") {
              let user = results.data.user;
              let token = results.data.token;
              // dark mode
              if (results.data.user.darkMode === "true") {
                vm.$vuetify.theme.dark = true;
              }
              vm.$store.commit("login", {
                username: user.username,
                role: user.role,
                firstname: user.firstname,
                lastname: user.lastname,
                darkMode: user.darkMode,
                token
              });
              localStorage.setItem("reddmintoken", token);

              // // redirect as needed
              vm.$router.push("/dashboard");
            } else {
              vm.$toasted
                .show("Invalid login... please try again", {
                  type: "error",
                  icon: "mdi-alert-circle"
                })
                .goAway(2500);
              vm.valid = false;
            }
          })
          .catch(function(error) {
            // handle error
            console.log(error);
            // alert("Invalid login... please try again");
            vm.$toasted
              .show("Invalid login... please try again", {
                type: "error",
                icon: "mdi-alert-circle"
              })
              .goAway(2500);
            vm.valid = false;
          });
      }
    },
    savePassword: function() {
      let vm = this;

      // basic validation
      if (vm.password.length >= 8 && vm.password === vm.password2) {
        axios({
          method: "post",
          url: vm.$root.urls.api + "/user/resetPassword",
          data: {
            username: vm.username,
            secret: vm.secret,
            password: vm.password
          }
        })
          .then(function(response) {
            if (response.status === 200) {
              // alert(vm.$t("message.passChanged"))
              // vm.$router.push('/')
              vm.login();
            }
          })
          .catch(function(error) {});
      } else {
        vm.$toasted
          .show("Password must be 8 characters or more, and must match", {
            type: "error",
            icon: "mdi-alert-circle"
          })
          .goAway(2500);
      }
    }
  },
  mounted: function() {
    // listen for unit id parameter
    if (this.$route.params.username) {
      this.username = this.$route.params.username;
    }
    if (this.$route.params.secret) {
      this.secret = this.$route.params.secret;
    }
  }
  // created: function() {
  //   // this.checkToken();
  // }
};
</script>

<style lang="scss">
.version {
  font-size: 10px;
  color: gray;
}
</style>
