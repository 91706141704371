<template>
  <v-container fluid class="primary">
    <v-row no-gutters style="height: 100vh" justify="center">
      <v-col md="4" align-self="center">
        <v-card class="pa-2 " outlined tile>
          <p class="text-center mt-10">
            <img
              src="@/assets/images/redd-text-logo.svg"
              alt=""
              style="width: 40%"
              class="mx-auto "
            />
          </p>
          <v-card-title>Please log in to continue</v-card-title>
          <v-card-subtitle
            >Not yet registered? Please see your office
            administrator.</v-card-subtitle
          >
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              Username
              <v-text-field
                v-model="username"
                :rules="usernameRules"
                required
              ></v-text-field>
              <div v-if="showForgotPassword === false">
                Password
                <v-text-field
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  @keyup.enter="login"
                  :rules="[rules.required, rules.min]"
                  required
                ></v-text-field>
              </div>
              <div v-if="showForgotPassword === true">
                <v-btn
                  color="primary"
                  class="px-4"
                  @click="sendPasswordResetLink"
                >
                  Send Password Reminder
                </v-btn>
              </div>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" class="px-4 ml-2" @click="login">
              Login
            </v-btn>
            <v-btn
              color="primary"
              text
              class="px-4 ml-2"
              @click="showForgotPassword = true"
            >
              Forgot Password?
            </v-btn>
            <span class="version ml-2">v3.20.4 </span>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LogRocket from "logrocket";
const axios = require("axios");
axios.defaults.withCredentials = true;
import helpers from "@/plugins/helpers";
import moment from "moment";
export default {
  data: () => ({
    valid: true,
    showForgotPassword: false,
    showPassword: false,
    username: "",
    password: "",
    usernameRules: [
      v => !!v || "Username is required",
      v => /.+@.+\..+/.test(v) || "Username must be a valid email address"
    ],
    rules: {
      required: value => !!value || "Required.",
      min: v => v.length >= 4 || "Min 4 characters",
      emailMatch: () => `The email and password you entered don't match`
    }
    // users: this.$store.state.users,
  }),
  computed: {
    token() {
      return this.$store.state.user.token;
    }
    // users() {
    //   return this.$store.state.users;
    // },
    // notificationDate() {
    //   return this.$store.state.notificationDate;
    // },
    // redirectAnnouncements() {
    //   return this.$store.state.redirectAnnouncements;
    // },
    // snapshotid() {
    //   return this.$store.state.snapshotid;
    // }
  },
  methods: {
    login() {
      let vm = this;
      if (this.$refs.form.validate()) {
        axios
          .post(vm.$root.urls.api + "/login/admin", {
            username: vm.username,
            password: vm.password
          })
          .then(function(results) {
            if (results.data.user && results.data.user.role !== "customer") {
              let user = results.data.user;
              let token = results.data.token;
              // dark mode
              if (results.data.user.darkMode === "true") {
                vm.$vuetify.theme.dark = true;
              }
              vm.$store.commit("login", {
                username: user.username,
                role: user.role,
                firstname: user.firstname,
                lastname: user.lastname,
                darkMode: user.darkMode,
                token
              });
              localStorage.setItem("reddmintoken", token);

              // identify user to log rocket
              LogRocket.identify(user._id, {
                name: `${user.title} ${user.firstname} ${user.lastname}`,
                email: user.username
              });

              // // get booking data
              // vm.getBookings()

              // // get unit data
              // vm.getUnits()

              // // get user data
              // vm.getUsers()

              // update unread notifications counter
              // vm.$store.commit("updateNotification", {
              //   notifications: user.unread.length
              // });

              // log the login activity
              // vm.logLogin();

              // load CMS content
              // vm.getCms();

              // load Bulletins
              // vm.getBulletins();

              // load documents via API
              // vm.getDocuments();

              // // redirect as needed
              vm.$router.push("dashboard");
            } else {
              // console.log("invalid login");
              // alert("Invalid login... please try again");
              vm.$toasted
                .show("Invalid login... please try again", {
                  type: "error",
                  icon: "mdi-alert-circle"
                })
                .goAway(2500);
              vm.valid = false;
            }
          })
          .catch(function(error) {
            // handle error
            console.log(error);
            // alert("Invalid login... please try again");
            vm.$toasted
              .show("Invalid login... please try again", {
                type: "error",
                icon: "mdi-alert-circle"
              })
              .goAway(2500);
            vm.valid = false;
          });
      }
    },
    // getBookings() {
    //   //v3 get booking data
    //   let vm = this
    //   axios.defaults.headers.common = {
    //     Authorization: `Bearer ${vm.token}`,
    //   };
    //   axios.get(vm.$root.urls.api + '/v3/bookings', {
    //       withCredentials: true
    //     })
    //     .then(function (response) {
    //       var bookings = response.data
    //       // TODO loop bookings and insert customer data

    //       // STORE BOOKING DATA IN STATE
    //       vm.$store.commit('setBookings', {
    //         bookings
    //       })
    //     });
    // },
    // getUnits() {
    //   //v3 get unit data
    //   let vm = this
    //   axios.defaults.headers.common = {
    //     Authorization: `Bearer ${vm.token}`,
    //   };
    //   axios.get(vm.$root.urls.api + '/v3/units', {
    //       withCredentials: true
    //     })
    //     .then(function (response) {
    //       var units = response.data

    //       // STORE BOOKING DATA IN STATE
    //       vm.$store.commit('setUnits', {
    //         units
    //       })
    //     });
    // },
    // getUsers() {
    //   //v3 get user data
    //   let vm = this
    //   axios.defaults.headers.common = {
    //     Authorization: `Bearer ${vm.token}`,
    //   };
    //   axios.get(vm.$root.urls.api + '/v3/users', {
    //       withCredentials: true
    //     })
    //     .then(function (response) {
    //       var users = response.data

    //       // STORE USER DATA IN STATE
    //       vm.$store.commit('setUsers', {
    //         users
    //       })
    //     });
    // },
    // getDocuments() {
    //   let vm = this;
    //   // load mock data
    //   // this.rawData = rawData;
    //   // let docs = this.rawData;

    //   // load documents via API
    //   axios.defaults.headers.common = {
    //     Authorization: `Bearer ${vm.token}`
    //   };
    //   axios.get(vm.$root.urls.api + "/documents", {}).then(function(results) {
    //     // store in application state
    //     vm.$store.commit("saveDocuments", {
    //       documents: results.data
    //     });

    //     // redirect as needed
    //     vm.redirect();

    //     // // did the user try to view announcements? If so, redirect to announcements (bulletins)
    //     // if (vm.redirectAnnouncements) {
    //     //   vm.$router.push("bulletins");
    //     // } else if (vm.notificationDate) {
    //     //   // redirect to notifications
    //     //   vm.$router.push("notifications");
    //     // } else {
    //     //   // redirect to dash
    //     //   vm.$router.push("dashboard");
    //     // }
    //   });
    // },
    // redirect() {
    //   let vm = this;

    //   // DEBUG

    //   console.log(`Documents: ${vm.$store.state.documents.length}`);

    //   // did the user try to view announcements? If so, redirect to announcements (bulletins)
    //   if (vm.redirectAnnouncements) {
    //     vm.$router.push("bulletins");
    //   } else if (vm.notificationDate) {
    //     // redirect to notifications
    //     vm.$router.push("notifications");
    //   } else if (vm.snapshotid) {
    //     // redirect to snapshot
    //     vm.$router.push(`snapshots/${vm.snapshotid}`);
    //   } else {
    //     // redirect to dash
    //     vm.$router.push("dashboard");
    //   }
    // },
    // getCms() {
    //   let vm = this;
    //   axios.defaults.headers.common = {
    //     Authorization: `Bearer ${vm.token}`
    //   };
    //   axios.get(vm.$root.urls.api + "/cms", {}).then(function(results) {
    //     // store in application state
    //     vm.$store.commit("updateDashboard", {
    //       cms: results.data
    //     });
    //   });
    // },
    // getBulletins() {
    //   let vm = this;
    //   axios.defaults.headers.common = {
    //     Authorization: `Bearer ${vm.token}`
    //   };
    //   axios.get(vm.$root.urls.api + "/bulletin", {}).then(function(results) {
    //     // store in application state
    //     vm.$store.commit("updateBulletins", {
    //       bulletins: results.data
    //     });
    //   });
    // },
    // getNotifications() {
    //   let vm = this;
    //   axios.defaults.headers.common = {
    //     Authorization: `Bearer ${vm.token}`
    //   };
    //   axios.get(vm.$root.urls.api + "/user", {}).then(function(results) {
    //     // update unread notifications counter
    //     vm.$store.commit("updateNotification", {
    //       notifications: results.data[0].unread.length
    //     });
    //   });
    // },
    // logLogin() {
    //   let vm = this;
    //   let activity = {
    //     user: {
    //       username: `${this.$store.state.user.firstname} ${this.$store.state.user.lastname}`,
    //       email: this.$store.state.user.username
    //     },
    //     date: moment().format("MM/DD/YYYY"),
    //     action: `Logged in`
    //   };
    //   helpers.log(vm.token, vm.$root.urls.api, activity);
    //   // vm.$store.commit("addActivity", {
    //   //   activity,
    //   // });
    // },
    sendPasswordResetLink() {
      let vm = this;
      if (this.$refs.form.validate()) {
        axios
          .post(vm.$root.urls.api + "/email/sendPasswordReset", {
            username: vm.username
          })
          .then(function(results) {
            // tell user that link has been sent
            vm.$toasted
              .show(
                "Thanks! Please check your email for a password reset link",
                {
                  type: "success",
                  icon: "mdi-thumb-up"
                }
              )
              .goAway(2500);
            // reset login form
            vm.showForgotPassword = false;
          });
      }
    }
    // checkToken() {
    //   let vm = this;
    //   // check to see if token is kept in local storage
    //   let token = localStorage.getItem("reddmintoken");

    //   if (token) {
    //     // parse token for user info
    //     var base64Url = token.split(".")[1];
    //     var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    //     var jsonPayload = decodeURIComponent(
    //       atob(base64)
    //         .split("")
    //         .map(function(c) {
    //           return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    //         })
    //         .join("")
    //     );

    //     let user = JSON.parse(jsonPayload);
    //     if (user.role) {
    //       this.$store.commit("login", {
    //         username: user.username,
    //         role: user.role,
    //         firstname: user.firstname,
    //         lastname: user.lastname,
    //         phone: user.phone,
    //         darkMode: user.darkMode,
    //         token
    //       });

    //       // dark mode
    //       if (user.darkMode === "true") {
    //         vm.$vuetify.theme.dark = true;
    //       }
    //       // // log the login activity
    //       // vm.logLogin();

    //       // // load CMS content
    //       // vm.getCms();

    //       // // load Bulletins
    //       // vm.getBulletins();

    //       // // load Notifications
    //       // vm.getNotifications();

    //       // // load documents via API
    //       // vm.getDocuments();

    //       // // redirect as needed
    //       vm.$router.push("dashboard");
    //     } else {
    //       vm.$toasted
    //         .show("Invalid login... please try again", {
    //           type: "error",
    //           icon: "mdi-alert-circle"
    //         })
    //         .goAway(2500);
    //     }
    //   }
    // }
  },
  mounted() {
    if (process.env.VUE_APP_UNAME) this.username = process.env.VUE_APP_UNAME;
    if (process.env.VUE_APP_PWRD) this.password = process.env.VUE_APP_PWRD;
  }
  // created: function() {
  //   // this.checkToken();
  // }
};
</script>

<style lang="scss">
.version {
  font-size: 10px;
  color: gray;
}
</style>
