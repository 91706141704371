const axios = require("axios");
axios.defaults.withCredentials = true;
import moment from "moment";
export default {
  foo: function() {
    console.log("foo!");
    alert("foo!");
  },
  log: function(token, url, activity) {
    let vm = this;
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`
    };
    axios
      .post(url + "/activity", {
        activity
      })
      .then(function(results) { });
  },
  formatFriendlyDate: function(d) {
    // let friendlyDate = moment(d)
    return moment(d).format("DD MMMM YYYY");
  },
  formatActivityDateTime: function(d) {
    // let friendlyDate = moment(d)
    return moment(d).format("YYYY-MM-DD h:mm a");
  },
  formatFriendlyDateTime: function(d) {
    // let friendlyDate = moment(d)
    return moment(d).format("MMMM Do YYYY, h:mm:ss a");
  },
  formatLocalDateTime(d) {
    return moment(d).local();
  },
  formatFriendlyLocalDate(d) {
    return moment(d)
      .local()
      .format("DD MMM YYYY");
  },
  isTransactionWithinPrevious12Months(transaction) {
    const startDate = moment()
      .subtract(1, "month")
      .startOf("day")
      .startOf("month")
      .subtract(1, "year");
    const endDate = moment()
      .subtract(1, "month")
      .endOf("day")
      .endOf("month");

    if (
      moment(transaction.dateCreated)
        .utcOffset("+07:00")
        .startOf("day")
        .isSameOrAfter(startDate) &&
      moment(transaction.dateCreated)
        .utcOffset("+07:00")
        .startOf("day")
        .isSameOrBefore(endDate)
    ) {
      return true;
    }
    return false;
  },
  isPaymentLinkPurchaseWithinPrevious12Months(paymentLink) {
    const startDate = moment()
      .subtract(1, "month")
      .startOf("day")
      .startOf("month")
      .subtract(1, "year");
    const endDate = moment()
      .subtract(1, "month")
      .endOf("day")
      .endOf("month");

    if (
      moment
        .utc(paymentLink.charge.used_at)
        .startOf("day")
        .isSameOrAfter(startDate) &&
      moment
        .utc(paymentLink.charge.used_at)
        .startOf("day")
        .isSameOrBefore(endDate)
    ) {
      return true;
    }
    return false;
  },
  includesTransactionsAfter(transactions, dateStart) {
    let vm = this;
    let isAfter = transactions.filter(t => {
      return (
        moment(t.dateCreated)
          .utcOffset("+07:00")
          .startOf("day")
          .isSameOrAfter(moment(dateStart).utcOffset("+07:00")) &&
        typeof t.amount !== "undefined" &&
        vm.formatStringNumber(t.amount) > 0
      );
    });
    if (isAfter.length) {
      return true;
    }
    return false;
  },
  includesTransactionsBefore(transactions, dateEnd) {
    let vm = this;
    let isBefore = transactions.filter(t => {
      return (
        moment(t.dateCreated)
          .utcOffset("+07:00")
          .isSameOrBefore(
            moment(dateEnd)
              .utcOffset("+07:00")
              .endOf("day")
          ) &&
        typeof t.amount !== "undefined" &&
        vm.formatStringNumber(t.amount) > 0
      );
    });
    if (isBefore.length) {
      return true;
    }
    return false;
  },
  compYTDSpendingTier(ytdSpending) {
    if (Number(ytdSpending) < 75000) return "TIER 0";
    if (Number(ytdSpending) >= 75000 && Number(ytdSpending) < 150000)
      return "TIER 1";
    if (Number(ytdSpending) >= 150000 && Number(ytdSpending) < 500000)
      return "TIER 2";
    if (Number(ytdSpending) >= 500000) return "TIER 3";
    return "";
  },
  formatBlank(value) {
    if (value === "undefined") return "";
    if (value === null) return "";
    if (typeof value === "undefined") return "";
    return value;
  },
  formatStringNumber(s) {
    let num = String(s);
    num = num.replace(/[^\d.]/g, "");
    return Number(num);
  },

  totalSquareMeters(query) {
    console.log("query", query);

    // console.log("query.units", query.units.length);

    // filter on commissioned and decommissioned dates
    const queryDate = moment(query.date);
    let commissionedUnits = query.units.filter(d => {
      return (
        moment(d.commissioned).isSameOrBefore(queryDate) &&
        moment(d.decommissioned).isSameOrAfter(queryDate)
      );
    });

    // console.log("commissionedUnits", commissionedUnits.length);

    // if filterbookings = false, then return all units
    if (!query.filterBookings) {
      // calculate total square meters of all commissioned units
      let totalSquareMeters = 0;
      commissionedUnits.forEach(unit => {
        totalSquareMeters += unit.width * unit.length;
      });
      return Math.ceil(totalSquareMeters);
    }

    return 150000;
  }
};
